import React, { Component } from "react";
import { FaCocktail, FaHiking, FaShuttleVan, FaBeer } from "react-icons/fa";
import Title from "./Title";
export default class Services extends Component {
  state = {
    services: [
      {
        icon: <FaCocktail />,
        title: "Friendly Staff",
        info:
          "Spend some quality time with your family and friends peacefully. The staff will take care of the rest"
      },
      {
        icon: <FaHiking />,
        title: "Endless Hiking",
        info:
          "Get into those hiking boots and make your way through wildlife sanctuaries, riversides, hillocks and much more."
      },
      {
        icon: <FaShuttleVan />,
        title: "Automobile Availability",
        info:
          "Explore nearby places easily with the help of local automobile options suggested by staff."
      },
    
    ]
  };
  render() {
    return (
      <section className="services">
        <Title title="services" />
        <div className="services-center">
          {this.state.services.map(item => {
            return (
              <article key={`item-${item.title}`} className="service">
                <span>{item.icon}</span>
                <h6>{item.title}</h6>
                <p>{item.info}</p>
              </article>
            );
          })}
        </div>
      </section>
    );
  }
}