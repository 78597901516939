import React from 'react'
import { Link } from 'react-router-dom'
import Banner from '../components/Banner'
import Contact from '../components/contact'
import FeaturedRooms from '../components/FeaturedRooms'
import Hero from '../components/Hero'
import Services from '../components/Services'

export default function Home() {
  return (
    <div>
<Hero>
        <Banner
          title="Luxurious Homestays and Resorts"
          subtitle="deluxe Resorts available"
        >
          <Link to="/rooms" className="btn-primary">
            Our Resorts
          </Link>
        </Banner>
      </Hero>  
      <Services />
      <FeaturedRooms />
      <Contact />
        </div>
  )
}
