import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaAlignLeft, FaAlignRight } from "react-icons/fa";
import sarvaLogo from "../images/sarvaLogo.png";
export default class Navbar extends Component {
  state = {
    isOpen: false
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
          <button
              type="button"
              className="nav-btn"
              onClick={this.handleToggle}
            >
              <FaAlignLeft className="nav-icon" />
            </button>
            <Link to="/">
              <img src={sarvaLogo} alt="Sarva Resort" style={{maxHeigth:"3rem", maxWidth:"8rem"}}/>
              {/* <div className="logo-styling">Sarva Resorts</div> */}
            </Link>
           
          </div>
          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/rooms">Resorts</Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
