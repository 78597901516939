import room1 from "./images/details-1.jpeg";
import room2 from "./images/details-2.jpeg";
import room3 from "./images/details-3.jpeg";
import room4 from "./images/details-4.jpeg";
import img1 from "./images/room-1.jpeg";
import img2 from "./images/room-2.jpeg";
import img3 from "./images/room-3.jpeg";
import img4 from "./images/room-4.jpeg";
import img5 from "./images/room-5.jpeg";
import img6 from "./images/room-6.jpeg";
import img7 from "./images/room-7.jpeg";
import img8 from "./images/room-8.jpeg";
import img9 from "./images/room-9.jpeg";
import img10 from "./images/room-10.jpeg";
import img11 from "./images/room-11.jpeg";
import img12 from "./images/room-12.jpeg";
import img13 from "./images/madikeri.jpeg";
import madikeri1img1 from "./images/madikeri1/madikeri1img1.jpeg";
import madikeri1img2 from "./images/madikeri1/madikeri1img2.jpeg";
import madikeri1img3 from "./images/madikeri1/madikeri1img3.jpeg";
import madikeri1img4 from "./images/madikeri1/madikeri1img4.jpeg";
import madikeri1img5 from "./images/madikeri1/madikeri1img5.jpeg";
import madikeri1img6 from "./images/madikeri1/madikeri1img6.jpeg";
import madikeri1img7 from "./images/madikeri1/madikeri1img7.jpeg";
import madikeri1img8 from "./images/madikeri1/madikeri1img8.jpeg";
import kush1img1 from "./images/kushalnagar1/kush1img1.jpeg"
import kush1img2 from "./images/kushalnagar1/kush1img2.jpeg"
import kush1img3 from "./images/kushalnagar1/kush1img3.jpeg"
import kush1img4 from "./images/kushalnagar1/kush1img4.jpeg"
import kush1img5 from "./images/kushalnagar1/kush1img5.jpeg"
import sakles1img1 from "./images/Sakles1/sakles1img1.jpeg";
import sakles1img2 from "./images/Sakles1/sakles1img2.jpeg";
import sakles1img3 from "./images/Sakles1/sakles1img3.jpeg";
import sakles1img4 from "./images/Sakles1/sakles1img4.jpeg";
import harangi1img1 from "./images/Harangi/harangi1img1.jpeg";
import harangi1img2 from "./images/Harangi/harangi1img2.jpeg";
import harangi1img3 from "./images/Harangi/harangi1img3.jpeg";
import harangi1img4 from "./images/Harangi/harangi1img4.jpeg";
import harangi1img5 from "./images/Harangi/harangi1img5.jpeg";
import chik1img1 from "./images/chiklihole/chik1img1.jpeg";
import chik1img2 from "./images/chiklihole/chik1img2.jpeg";
import chik1img3 from "./images/chiklihole/chik1img3.jpeg";
import chik1img4 from "./images/chiklihole/chik1img4.jpeg";
import chik1img5 from "./images/chiklihole/chik1img5.jpeg";
import chik1img6 from "./images/chiklihole/chik1img6.jpeg";
import chik1img7 from "./images/chiklihole/chik1img7.jpeg";
import madikeri2img1 from "./images/madikeri2/madikeri2img1.jpeg";
import madikeri2img2 from "./images/madikeri2/madikeri2img2.jpeg";
import madikeri2img3 from "./images/madikeri2/madikeri2img3.jpeg";
import madikeri2img4 from "./images/madikeri2/madikeri2img4.jpeg";
import madikeri2img5 from "./images/madikeri2/madikeri2img5.jpeg";
import madikeri2img6 from "./images/madikeri2/madikeri2img6.jpeg";
import madikeri2img7 from "./images/madikeri2/madikeri2img7.jpeg";
import madikeri2img8 from "./images/madikeri2/madikeri2img8.jpeg";
import madikeri2img9 from "./images/madikeri2/madikeri2img9.jpeg";
import agumbe1img1 from "./images/agumbe1/agumbe1img1.jpeg";
import agumbe1img2 from "./images/agumbe1/agumbe1img2.jpeg";
import agumbe1img3 from "./images/agumbe1/agumbe1img3.jpeg";
import agumbe1img4 from "./images/agumbe1/agumbe1img4.jpeg";
import agumbe1img5 from "./images/agumbe1/agumbe1img5.jpeg";




export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "Madikeri ",
      slug: "Madikeri-Homestay-Property-1",
      type: "madikeri",
      price: "150",
      size: 200,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
            "🏝Package includes",
        "🍱2 meals",
       " 🔥campfire and music ",
        "🪢Rope activities",
        "🏡Estate walk",
        "🏐Mud volleyball",
        "🏕Tree tyre climb",
        "🌊Pvt waterfalls",
        "🏊‍♂️ (Natural) waterfalls",
        "Tent stay and dormitory also available"
      ],
      images: [
        {
          fields: {
            file: {
              url: madikeri1img1
            }
          }
        },
        // {
        //   fields: {
        //     file: {
        //       url: madikeri1img1
        //     }
        //   }
        // },
        {
          fields: {
            file: {
              url: madikeri1img2
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri1img4
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri1img7
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri1img6
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri1img5
            }
          }
        },
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "Kushalnagar",
      slug: "kushalnagar-property-1",
      type: "Kushalnagar",
      price: 150,
      size: 250,
      capacity: 10,
      pets: false,
      breakfast: false,
      featured: false,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
        "🏝Package includes",
        "🍱2 meals",
       " 🔥campfire and music ",
        "🪢Rope activities",
        "🏡Estate walk",
        "🏐Mud volleyball",
        "🏕Tree tyre climb",
        "🌊Pvt waterfalls",
        "🏊‍♂️ (Natural) waterfalls",
        "Tent stay and dormitory also available"
      ],
      images: [
        {
          fields: {
            file: {
              url: kush1img5
            }
          }
        },
        {
          fields: {
            file: {
              url:  kush1img2
            }
          }
        },
        {
          fields: {
            file: {
              url:  kush1img1
            }
          }
        },
        {
          fields: {
            file: {
              url:  kush1img4
            }
          }
        }, {
          fields: {
            file: {
              url:  kush1img1
            }
          }
        },
      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "Sakleshpura ",
      slug: "sakleshpura-homestay-property-1",
      type: "sakaleshpura",
      price: 250,
      size: 300,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
     
"With three times food",
"Outdoor games",
"Camp fire music",
      ],
      images: [
        {
          fields: {
            file: {
              url: sakles1img1
            }
          }
        },
        {
          fields: {
            file: {
              url: sakles1img1
            }
          }
        },
        {
          fields: {
            file: {
              url: sakles1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: sakles1img4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "Harangi (KushalNagar)",
      slug: "harangi-homestay",
      type: "Kushalnagar",
      price: 300,
      size: 400,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
        "Unlimited food",
        "Estate Walk",
        "Backwater Stay",
        "Campfire",
        "Outdoor games",
        "Indoor games",
      ],
      images: [
        {
          fields: {
            file: {
              url: harangi1img1
            }
          }
        },
        {
          fields: {
            file: {
              url: harangi1img2
            }
          }
        },
        {
          fields: {
            file: {
              url: harangi1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: harangi1img4
            }
          }
        },
        {
          fields: {
            file: {
              url: harangi1img5
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "ChikliHole (KushalNagar)",
      slug: "chiklihole-homestay",
      type: "Kushalnagar",
      price: 300,
      size: 400,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
        "Unlimited food",
        "Campfire",
        "DJ",
        "Riversidw property",
        "Outdoor games",
        "Indoor games",
      ],
      images: [
        {
          fields: {
            file: {
              url: chik1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: chik1img2
            }
          }
        },
        {
          fields: {
            file: {
              url: chik1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: chik1img4
            }
          }
        },
        {
          fields: {
            file: {
              url: chik1img5
            }
          }
        }, {
          fields: {
            file: {
              url: chik1img6
            }
          }
        }, {
          fields: {
            file: {
              url: chik1img1
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "6"
    },
    fields: {
      name: "Agumbe ",
      slug: "agumbe-homestay",
      type: "Agumbe",
      price: 300,
      size: 400,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
        "Private Homestay",
        "Hill stations",
        "Nature",
        "Greenery",
        "Photography Spots",
        "Swimming Pool"
        
      ],
      images: [
        {
          fields: {
            file: {
              url: agumbe1img4
            }
          }
        },
        {
          fields: {
            file: {
              url: agumbe1img2
            }
          }
        },
        {
          fields: {
            file: {
              url: agumbe1img3
            }
          }
        },
        {
          fields: {
            file: {
              url: agumbe1img4
            }
          }
        },
        {
          fields: {
            file: {
              url: agumbe1img5
            }
          }
        },
      ]
    }
  },
  {
    sys: {
      id: "7"
    },
    fields: {
      name: "Madikeri (Mekeri)",
      slug: "Madikeri-homestay",
      type: "madikeri",
      price: 300,
      size: 400,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:"",
        // "Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.",
      extras: [
        "Private Homestay",
        "Hill stations",
        "Nature",
        "Greenery",
        "Photography Spots",
        "indoor games"
        // "Swimming Pool"
        
      ],
      images: [
        // {
        //   fields: {
        //     file: {
        //       url:madikeri2img1
        //     }
        //   }
        // },
        {
          fields: {
            file: {
              url: madikeri2img5
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri2img3
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri2img4
            }
          }
        },
        {
          fields: {
            file: {
              url: madikeri2img2
            }
          }
        }, {
          fields: {
            file: {
              url: madikeri2img6
            }
          }
        }, 
      ]
    }
  },
];
