import Rooms from "./pages/Rooms";
import SingleRoom from "./pages/SingleRoom";
import Error from "./pages/Error";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import { FaWhatsappSquare } from "react-icons/fa";


function App() {
  
  return (
    <div className="App">
    <Navbar />
    <Routes>
        <Route  path="/" element={<Home />} />
        <Route path="/rooms/" element={<Rooms />} />
        <Route exact path="/rooms/:slug" element={<SingleRoom />} />
        <Route exact path="/error/" element={<Error />} />
        
      </Routes>
   
<div class="floating_btn">
    <a target="_blank" href="https://wa.me/918431091391">
      <div class="contact_icon">
        <i class="fa fa-whatsapp my-float"></i>
      </div>
    </a>
    <p class="text_icon">Talk to us?</p>
  </div>
    </div>
  );
}

export default App;
