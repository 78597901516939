import React , {useRef,useState}from "react";
import { useNavigate } from "react-router-dom";
import whatsappIcon from "../images/whatsappIcon.png";
import { FaWhatsappSquare } from "react-icons/fa";
import emailjs from "@emailjs/browser";


export default function Contact() {
  const navigate = useNavigate();
const[username, setUsername] = useState('')
const[emailid, setemailid] = useState('')
const[number, setNumber] = useState('')
const[query, setQuery] = useState('')
const[location, setLocation] = useState('')
const [text,setText]= useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_f27p5ho",
        "template_4fd8x3x",
        form.current,
        "QzQl7js6xA1mn56yS"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setLocation('')
          setNumber('')
          setQuery('')
          setUsername('')
          setemailid('')
          setText(true);
        },
        // (error) => {
        //   console.log(error.text);
        // }
      );
  };
  return (
    
   <div>
    {!text&&<form ref={form} onSubmit={sendEmail} >
      
      <h3>Contact us</h3>
      
      <fieldset>
  

        <label>Name</label>
        <input type="text" name="username" onChange={(e)=>{setUsername(e.target.value);console.log(e.target.value)}}/>
        <label>Email</label>
        <input type="email" name="emailid" onChange={(e)=>{setemailid(e.target.value);console.log(e.target.value)}}/>
        <label>Phone Number</label>
        <input type="number" name="number" onChange={(e)=>{setNumber(e.target.value);console.log(e.target.value)}}/>
        <label>Location</label>
        <input type="text" name="location" onChange={(e)=>{setLocation(e.target.value);console.log(e.target.value)}}/>
        <label>Queries</label>
        <input type="text" name="query" onChange={(e)=>{setQuery(e.target.value);console.log(e.target.value)}}/>

        {/* <label>Message</label> */}
        <textarea style={{display:"none"}} name="message" value={`name:${username}, number:${number}, email:${emailid}, query:${query}, location:${location}`}/>
        {/* <input type="submit" value="Send" /> */}
      </fieldset>
      
     
      <button type="submit">Submit</button>
    </form>}
    {text&& <p style={{textAlign:"center", width:"80%", background:"green", color:"white", margin:"1rem auto"}}>Your Response has been submitted successfully, We will contact you shortly</p>}
   
   <div style={{textAlign:"center"}}> <div> Call</div>
        <h3>+91 9632467873</h3>
         <div>or</div>
           <h3> +91 9986868547</h3>
           <div>for more info.</div>
           </div>
   </div>
  );
}
